h1 {
    text-align: center;
}

.AboutMe {
    font: Arial;
}

#name {
    font-weight: bold;
}