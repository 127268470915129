.Cookies {
    text-align: center;
    font: Arial;
}
.spacingP {
    margin: 5px;
}

#cookies-text {
    font-weight: bold;
    color: BurlyWood;
}