nav {
  text-align: center;
}
footer {
  font-family: Sans-Serif;
  text-align: center;
}

#no-underline-size-up {
  font-size: 20px;
  text-decoration: none;
}